$breakpoints: (
        'xs-phone': 320px,
        'phone': 375px,
        'tablet': 768px,
        'desktop': 1024px,
        'widescreen': 1200px,
);

/*keywords*/
$media-expressions: (
        'screen': 'screen',
        'print': 'print',
        'handheld': 'handheld',
        'landscape': '(orientation: landscape)',
        'portrait': '(orientation: portrait)',
        'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
        'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'vendors/include-media';
@import 'vendors/normalize';

@import "base/variables";

@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/reset';
@import 'base/forms';
@import 'base/typography';

@import 'layouts/spinner';
@import 'layouts/default';
@import 'layouts/header';
@import 'layouts/info-page';
@import 'layouts/main';
@import 'layouts/order-summary';
@import 'layouts/popup';
@import 'layouts/footer';

.resize-active * {
  transition: none !important;
}

#the-app {
  position: relative;
}

#wrapper {
  overflow: hidden;
  position: relative;
  &.default-overlay {
    height: 100vh;
  }

  &.popup-active {

  }
}



