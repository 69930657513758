html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

* {max-height: 1000000px;}

body {
	color: $base-text-color;
	background: $base-background-color;
	font: #{$font-size-base}/#{$line-height-base} $base-font-family;
	min-width: $base-min-width;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.bp3-overlay-open {
		& .bp3-portal {
			z-index: 1000000;
		}
	}
}

// adaptive images
img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
}

// google map fix
.gm-style img {max-width: none}


