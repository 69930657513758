.popup-overlay {
  display: none;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;

  &.popup-active {
    display: block;
  }

  &.popup-window {
    display: flex;
    overflow-y: auto;
    .popup {
      max-width: 1024px;
      margin: 0 auto;
      align-items: center;
    }
  }
}

.popup-window {
  &.confirmation-popup {
    .popup {
      width: auto;
    }
  }
  .text-block {
    background-color: $white;
    padding: 20px;
    flex: 1;
    text-align: center;
    border-radius: 10px;
    max-width: 100%;
    overflow-wrap: break-word;
    color: $black;
    font-size: 14px;
    font-weight: normal;
  }
  button {
    margin: 0 auto;
  }

  .btn-group {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .cancel-btn {
    background-color: #ff5f13;
    transition: opacity .4s;
    &:hover {
      opacity: .7;
    }
  }
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 1024px;
  padding: 30px;
  display: flex;
}

.popup-image-wrap {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
}
