img {
  width: 100%;
}
main {
  min-height: calc(100vh - 227px);
  background-color: $white;
  padding: 163px 0 64px;

  .checkout & {
    min-height: calc(100vh - 253px);
    padding: 163px 0 90px;
  }
}

.btn {
  background-color: $green;
  color: $white;
  text-decoration: none;
  text-align: center;
  padding: 7px;
  border-radius: 17px;
  font-family: $base-font-sans-serif;
  display: block;
  font-size: 16px;
  line-height: 1;
  transition: background-color .4s;
  font-weight: 700;
  min-width: 105px;

  @include media('>=phone') {
    padding-left: 15px;
    padding-right: 15px;
    min-width: 118px;
  }

  &.disable {
    background-color: $gray;
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }

}

.btn-wrap {
  padding-top: 10px;
  padding-bottom: 10px;
}

.action-wrap {
  display: flex;
  justify-content: center;
}

.btn-back {
  background-color: $green;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: relative;

  &:before {
    content: '';
    width: 7px;
    height: 7px;
    display: block;
    border-left: 2px solid $white;
    border-bottom: 2px solid $white;
    transform: rotateZ(45deg) translateY(-5px);
    position: absolute;
    top: 50%;
    left: 3px;
  }

  &:after {
    content: '';
    height: 2px;
    background-color: $white;
    position: absolute;
    top: 50%;
    left: 6px;
    right: 6px;
    transform: translateY(-50%);
  }
}

.container {
  padding: 0 20px;
  max-width: 1024px;
  margin: 0 auto;
}

.count-button {
  width: 18px;
  height: 18px;
  min-width: auto;
  min-height: auto;
  border: 1px solid $green;
  border-radius: 50%;
  position: relative;
  background-color: transparent;
  padding: 0;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 2px;
    right: 2px;
    height: 1px;
    transform: translateY(-50%);
    background-color: $green;
    display: block;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 2px;
    bottom: 2px;
    width: 1px;
    transform: translateX(-50%);
    background-color: $green;
    display: block;
  }

  &:before, &:after {
    transition: background-color .4s;
  }

  &.decrement-count {
    &:after {
      display: none;
    }
  }

  &.close {
    transform: rotatez(45deg);
    position: absolute;
    top: 10px;
    right: 10px;
    border: 2px solid $white;
    width: 24px;
    height: 24px;

    &:before, &:after {
      background-color: $white;
    }

    &:before {
      height: 2px;
    }

    &:after {
      width: 2px;
    }
  }
}

button {
  &.count-button {
    &:disabled {
      background-color: transparent;
      border-color: $gray;
      &:before, &:after{
        background-color: $gray;
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.product-group {
  padding-top: 20px;
  &:last-child {
    min-height: calc(100vh - 109px);
  }

  &:last-child {
    padding-bottom: 40px;
  }
  h3 {
    width: 100%;
    text-align: left;
  }
}
.product {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid $middle-gray;
  transition: padding-bottom .4s;

  .form-field-error {
    left: auto;
    right: 0;
    bottom: 5px;
    line-height: 1;
    @include media('<phone') {
      bottom: 3px;
    }
  }

  &.product-custom-price-error {
    padding-bottom: 30px;

    @include media('<phone') {
      padding-bottom: 35px;
    }
  }

  p {
    flex: 1;
    margin-bottom: 5px;
  }

  input {
    width: 139px;
    font-size: 11px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 0;

    @include media('<phone') {
      padding-left: 5px;
      padding-right: 5px;
    }

    &.custom-price {
      width: 66px;
      height: 22px;
      text-align: center;
    }
  }

  .image-wrap {
    width: 76px;
    cursor: pointer;
    position: relative;

    .icon {
      width: 12px;
      height: 12px;
      position: absolute;
      right: 5px;
      bottom: 5px;
      &:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid $black;
        background-color: rgba(255,255,255,0.75);
        position: absolute;
        top: 0;
        left: 0;
        -webkit-box-shadow: 0px 0px 5px 5px rgba(255,255,255,0.75);
        -moz-box-shadow: 0px 0px 5px 5px rgba(255,255,255,0.75);
        box-shadow: 0px 0px 5px 5px rgba(255,255,255,0.75);
      }
      &:after {
        content: '';
        width: 2px;
        height: 8px;
        position: absolute;
        left: 11px;
        top: 8px;
        display: block;
        background-color: $black;
        transform: rotateZ(-45deg);
        -webkit-box-shadow: 0px 0px 5px 1px rgba(255,255,255,0.75);
        -moz-box-shadow: 0px 0px 5px 1px rgba(255,255,255,0.75);
        box-shadow: 0px 0px 5px 1px rgba(255,255,255,0.75);
      }

      .plus {
        width:12px;
        height: 12px;
        position: absolute;
          &:before, &:after {
            content: '';
            position: absolute;
            display: block;
            z-index: 100;
            background-color: $black;
          }
          &:before {
            height: 2px;
            top: 50%;
            left: 3px;
            right: 3px;
            transform:translateY(-50%);
          }

          &:after {
            width: 2px;
            top: 3px;
            bottom: 3px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
    }
  }

  .product-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 10px;

    h3 {
      width: 100%;
      text-align: left;
      margin-bottom: 2px;
      line-height: 1;
    }
  }

  .col-wrap {
    display: flex;
    flex: 1;
  }

  .col-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }

  .col:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 8px;
  }

  .product-price {
    color: $gray;
    flex: 1;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
  }

  .count-wrap {
    display: flex;
    align-items: center;

    .count {
      min-width: 34px;
      padding: 0 5px;
      text-align: center;
      font-weight: 700;
    }
  }
}

/* Tooltip container */
.input-tooltip-wrap {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.input-tooltip-wrap .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: $white;
  color: $black;
  text-align: center;
  font-size: 11px;
  border-radius: 6px;
  border: 1px solid $black;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1000;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  &:before {
    @include triangle(8px, $black, down);
    content: '';
    position: absolute;
    bottom: -4px;
    left: 15px;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.input-tooltip-wrap:hover .tooltiptext {
  visibility: visible;
}

