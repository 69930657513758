.footer {
  background-color: $black;
  color: $white;
  font-weight: 700;
  font-size: 16px;
  padding: 17px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .logo, .total-price {
    min-height: 30px;
  }

  .logo {
    width: 80px;
    display: flex;
    align-items: center;

    @include media('>=phone') {
      width: 100px;
    }
  }
  .link-wrap {
    text-align: center;
  }

  .tac {
    font-size: 12px;
  }

  .total-price {
    font-weight: 500;
  }

}
