// forms

form,
fieldset {
	margin: 0;
	padding: 0;
	border-style: none;
}

input,
input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
	//input[type='url'],
input[type='date'],
textarea {
	-webkit-appearance: none;
	-webkit-border-radius: 17px;
	box-sizing: border-box;
	border: 1px solid $form-element-border-color;
	padding: $form-element-padding;
	background-color: $form-element-border-color;
	font-size: 12px;
	border-radius: 17px;
	width: 100%;
	outline: none;
	color: $black;
	margin-bottom: 20px;


	// &:hover {

	// }

	&:focus {
		border-color: $form-element-focus-border-color;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 40px $form-element-border-color inset !important;
	}

	@include placeholder {
		color: $placeholder-color;
	}
	//.input-error &{
	//	border-color: $red;
	//	color: $red;
	//	@include placeholder {
	//		color: $red;
	//	}
	//}
}

textarea {
	resize: none;
	min-height: 166px;
	vertical-align: top;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	cursor: pointer;
	background-color: $green;
	border: none;
	color: $white;
	padding: 7px;
	border-radius: 17px;
	font-family: $base-font-sans-serif;
	display: block;
	font-size: 16px;
	line-height: 1;
	transition: background-color .4s;
	font-weight: 700;
	min-width: 105px;

	@include media('>=phone') {
		padding-left: 15px;
		padding-right: 15px;
		min-width: 118px;
	}

	&:hover {
		text-decoration: none;
		background-color: $gray;
	}
    &:focus, &:active {
        outline: none;
    }

	&:disabled {
		background-color: $gray;
	}
}

//button[type='submit']:disabled  todo maybe use
.btn-disabled {
	background-color: $light-gray !important;
	border: 1px solid $gray !important;
	color: $gray !important;
	&:hover {
		background-color: $light-gray !important;
		border: 1px solid $gray !important;
		color: $gray !important;
	}
}

label {
	display: block;
	font-weight: 700;
	text-align: left;
	margin-bottom: 3px;
}

input[type=checkbox], input[type=radio] {
	padding: 0;
	height: initial;
	width: initial;
	margin-bottom: 0;
	display: none;
	cursor: pointer;
	&:checked + {
		label {
			&:before {
				background-color: $green;
				border-color: $green;
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 10px;
				left: 6px;
				width: 6px;
				height: 10px;
				border: solid $white;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);
			}
		}
	}
	+ label {
		position: relative;
		cursor: pointer;
		display: inline-block;
		margin-bottom: 0;
		//margin-top: 5px;
		&:before {
			content:'';
			-webkit-appearance: none;
			background-color: transparent;
			border: 1px solid $gray;
			border-radius: 50%;
			padding: 8px;
			display: inline-block;
			position: relative;
			vertical-align: middle;
			cursor: pointer;
			margin-right: 17px;
			transition: border-color .4s, background-color .4s;
		}

		span {
			padding-left: 12px;
			margin-top: 3px;
			display: inline-block;
		}
	}
}

input[type="number"] {
	-moz-appearance:textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:hover {
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
}

.select-wrap {
	border: 1px solid $form-element-border-color;
	padding-right: 17px;
	background-color: $form-element-border-color;
	border-radius: 17px;

}

select {
	border: none;
	padding: $form-element-padding;
	background-color: $form-element-border-color;
	font-size: 12px;
	width: 100%;
	outline: none;
	color: $gray;
	border-radius: 17px;
}


.form-field-error {
	color: $red;
	font-size: 11px;
	position: absolute;
	left: 0;
	bottom: 0;
	display: block;
}

