.header {
  box-shadow: 0px 5px 10px 0px rgba(190,190,190,1);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  transition: top .4s;

  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 65px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.nav-hidden {
    box-shadow: none;

    .main-nav {
      display: none;
    }

    .btn-wrap {
      display: block;
    }

    .title-block {
      &:after {
        display: none;
      }
    }

  }
  
  nav {
    position: relative;
    &:before {
      content: '';
      background-color: $white;
      top: -20px;
      right: -9999px;
      left: -9999px;
      bottom: 0;
      position: absolute;
      z-index: 0;
    }
  }

  .btn-wrap {
    display: none;
  }

  &.banner-hidden {
    top: -118px;
  }

  .container {
    padding-top: 50px;
  }

  h1 {
    margin-bottom: 0;
    text-align: center;
  }

  .title-block {
    display: flex;
    align-items: center;
    background-color: $white;
    border-radius: 40px;
    z-index: 2;
    position: relative;
    padding: 4px 8px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $middle-gray;
    }
  }

  .logo {
    width: 60px;
    height: 60px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;

    &.active {
      visibility: hidden;
      width: 5px;
    }
  }

  .company-title {
    transition: .5s;

    &.active {
      display: none;
    }
  }

  .magnify-box {
    margin-right: 10px;
    height: 40px;
    width: 60px;
    display: flex;
    justify-content: flex-start;
    border-radius: 30px;
    padding-left: 2px;
    padding-right: 2px;
    transition: border .5s, width 1s;
    border: 2px solid transparent;
    margin-left: auto;
    
    &.search-hidden {
      visibility: hidden;
    }

    &.active {
      width: 100%;
      border: 2px solid lightgray;
    }

    .magnify-input {
      display: none;
      height: 40px;
      padding-left: 2px;
      background-color: transparent;
      border: none;
      font-size: 16px;

      &:focus {
        outline: none;
        outline-style: none;
        box-shadow: none;
        border: none;
      }
      

      &.active {
        display: inline-block;
      }
    }

    .magnify-img {
      width:40px;
      margin-left: 2px;
      transition: .5s;

      &.active {
        opacity: 30%;
      }
      
    }

    .magnify-cancel {
      width: 30px;
      align-self: center;
      opacity: 70%;

      &.hidden {
        visibility: hidden;
      }
      &.active {
        visibility: visible;
        margin-right: 10px;
      }
    }

  }
  
  h1 {
    flex: 1;
    padding-right: 60px;
  }

  .main-nav {
    @extend %listreset;
    //display: flex;
    //justify-content: center;
    //flex-wrap: wrap;
    overflow-y: auto;
    white-space: nowrap;
    text-align: center;
    font-size: 16px;
    position: relative;
    z-index: 1;
    padding-top: 7px;

    li {
      z-index: 2;
      position: relative;
      transition: background-color .4s;
      padding: 0 10px;
      margin-bottom: 12px;
      display: inline-block;
    }
    a {
      text-decoration: none;
      color: inherit;
      border-radius: 14px;
      line-height: 1;
      display: block;
      padding: 5px 17px;
      background-color: $white;
      transition: background-color .3s, color .3s;

      &:hover {
        cursor: pointer;
      }

      &.active {
        color: $white;
        background-color: $green;

      }
    }
  }

}
