.order-block {
  padding-top: 20px;
  padding-bottom: 45px;

  .order-list {
    padding: 0 10px;
    line-height: 1;

    @include media('>=phone') {
      padding: 0 42px;
    }
  }

  .order-item, .total-amount {
    .row-total {
      .col {
        width: 50%;

        &:last-child {
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }

  .row-total {
    margin-bottom: 12px;
  }

  .order-item {
    border-bottom: 1px solid $middle-gray;
    padding-top: 12px;

    &:last-child {
      border-bottom: none;
    }

    h3 {
      margin-bottom: 2px;
      line-height: 1;
    }

    .comment {
      display: block;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      margin-bottom: 10px;
      line-height: 1.3;
      font-style: italic;
      overflow: hidden;
    }
  }

  .row-total {
    font-weight: 700;
    display: flex;

  }

  .count-price-row {
    .col {
      width: auto;
      display: inline-block;
      &:last-child {
        padding-left: 5px;
      }
    }
  }

  .total-amount {
    font-weight: 700;
    font-size: 16px;
    padding: 12px 10px;
    position: relative;
    @include media('>=phone') {
      padding-left: 42px;
      padding-right: 42px;
    }

    .row {
      display: flex;
    }

    .col {
      width: 70%;
      &:last-child {
        width: 30%;
        text-align: right;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $middle-gray;
      @include media('>=phone') {
        left: 18px;
        right: 18px;
      }
    }
  }

}

.payment-block {
  padding-bottom: 45px;

  img {
    width: 50px;
  }

  .card-type {
    font-size: 16px;
  }

  .payment-list {
    padding: 0 10px;
    position: relative;

    @include media('>=phone') {
      padding: 0 42px;
    }

    &.invalid {
      input[type=checkbox] + label:before,
      input[type=radio] + label:before {
        border-color: $red;
      }
    }

    .form-field-error {
      position: relative;
    }
  }

  .payment-item {
    padding: 12px 0;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -10px;
      right: -10px;
      height: 1px;
      background-color: $middle-gray;
      @include media('>=phone') {
        left: -24px;
        right: -24px;
      }
    }

    &.payment-hidden {
      opacity: .3;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }
  }
}

.input-row {
  position: relative;
}

.information-block {
  padding-bottom: 40px;

  h2 {
    margin-bottom: 30px;
  }

  label {
    font-size: 16px;
  }
}
