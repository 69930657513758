.main-page {

  .header {
    box-shadow: none;

    &.banner-hidden {
      top: -150px;
    }

    .container {
      padding-top: 150px;
    }

    .title-block {
      position: relative;
      padding: 20px;

      &:before {
        content: "";
        background-color: #fff;
        top: 50%;
        right: -9999px;
        left: -9999px;
        bottom: 0;
        position: absolute;
        z-index: -1;
      }

      &:after {
        display: none;
      }
    }
  }

  .main-logo {
    width: 182px;
    margin: 0 auto;
  }

  main {
    padding-top: 220px;
    padding-bottom: 93px;
  }

  .footer {
    background-color: $green;

    .link-wrap {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .logo {
      width: 64px;
    }

    .container {
      justify-content: flex-start;
    }

    ul {
      @extend %listreset;
      flex: 1;
      padding-left: 40px;
      padding-right: 64px;
    }
  }
}

.info-block {
  padding: 0 10px 30px;
  font-size: 16px;
}

.action-block {
  @extend %listreset;
  margin-left: -20px;
  margin-right: -20px;
  display: flex;

  li {
    padding: 0 10px;
  }

}

.promo-block {
  padding-bottom: 50px;

  .img-wrap {
    padding-bottom: 30px;
  }
}
