.default-page-wrap {
  background-color: $black;
  color: $white;
  height: 100vh;
  position: relative;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .preload-page-logo {
    width: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .check-icon {
    width: 70px;
    height: 70px;
    margin: 0 auto 30px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 19px;
      width: 30px;
      height: 68px;
      border: solid $green;
      border-width: 0 8px 8px 0;
      transform: rotate(45deg);
    }
  }

  .cross-icon {
    width: 70px;
    height: 70px;
    margin: 0 auto 30px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: calc(50% - 4px); // center - half of width
      width: 8px;
      height: 75px;
      background-color: $white;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: calc(50% - 4px); // center - half of width
      width: 8px;
      height: 75px;
      background-color: $white;
      transform: rotate(-45deg);
    }
  }

  &.error-page .action {
    max-width: 100%;
  }

  .action {
    max-width: 118px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F5F5DC;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  padding: 15px;
  color: $black;

  .cross-icon {
    width: auto;
    height: auto;
    background-color: transparent;
    &:before, &:after {
      background-color: $black;
      width: 2px;
      height: 15px;
    }

  }
}
