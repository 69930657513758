// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$gray: #8a8a8a;
$black: #000000;
$white: #fff;
$light-blue: #80d2de;
$middle-gray: #dfdfdf;
$light-gray: #f2f2f2;
$red: #ff5b5b;
$green: #0cd354;


$base-font-sans-serif: 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $black !default;
$base-background-color: $white !default;
$font-size-base: 14px !default;
$line-height-base: 1.67 !default;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $green;

// Buttons
$btn-color: $white !default;
$btn-background: $black !default;
$btn-padding: 1em 1.45em  !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $light-gray !default;
$form-element-focus-border-color: $gray !default;
$placeholder-color: $gray !default;

// Headers
$h1-font-size: 20px !default;
$h2-font-size: 20px !default;
$h3-font-size: 16px !default;
$h4-font-size: 16px !default;
$h5-font-size: 14px !default;
$h6-font-size: 12px !default;

$headings-font-family: $base-font-sans-serif !default;
$headings-color: inherit !default;


$animation-speed: 0.3s;
